import { reactive } from 'vue'
import { useServiceCatalog } from '@/services/useServiceCatalog'
import { useResource } from '@/services'
import axios from '@/services/axios'
import { SaveCatalogFromUrlDto } from '@/utils/catalog'
interface Variant {
  _id?: string
  catalogVariantCode: string
  colorCode?: string
  cost?: number
  description?: string
  inStock?: boolean
  sizeCode?: string
}
export default function useCatalogRepositories() {
  const resource = `/catalogs`
  // const resource = `http://localhost:3003/api/catalogs`

  const bulkUpdate = async (body: any) => {
    const { patch } = useServiceCatalog(`${resource}/bulk-update`, body)
    return await patch(body)
  }

  const createCatalogFromUrl = async (dto: SaveCatalogFromUrlDto) => {
    return axios.post(`${resource}/catalog-from-store`, dto)
  }

  const exportCatalog = async (params: any) => {
    try {
      const res = await axios.get(`${resource}/export`, { params })
      return res
    } catch (error) {
      return null
    }
  }
  const getFilterList = async () => {
    try {
      const res = await axios.get(
        `public/${resource}/fulfillments/filters/list`
      )
      return res
    } catch (error) {
      return null
    }
  }

  const getCatalogs = async (params: any) => {
    try {
      const res = await axios.get(`${resource}`, { params })
      return res
    } catch (error) {
      return null
    }
  }

  const getAllCatalogTags = async () => {
    const res = await axios.get(`${resource}/common/tags`)
    if (res?.status === 200 || res?.status === 201) {
      return res.data as any
    }
  }

  const checkAllowCreateCatalog = async () => {
    const res = await axios.get(`agencies/config`)
    if (res?.status === 200 || res?.status === 201) {
      return res.data as any
    }
  }

  return {
    useServiceCatalog,
    bulkUpdate,
    exportCatalog,
    createCatalogFromUrl,
    getFilterList,
    getCatalogs,
    getAllCatalogTags,
    checkAllowCreateCatalog,
  }
}
