
import { useServiceCatalog } from '@/services/useServiceCatalog'
// import { useRouter } from 'vue-router'
import { defineComponent, reactive, PropType } from 'vue'
import { Edit, Delete, ArrowDown, DocumentCopy } from '@element-plus/icons'
import { useRouter } from 'vue-router'
import { Catalog } from '@/interfaces/catalog'
import { ElMessageBox } from 'element-plus'
import { useNotification } from '@/composables'
import SlotError from '@/components/common/SlotError.vue'
import { imageUrl } from '@/utils/image'
import publishCatalogs from '@/repositories/useCatalogRepositories'
import axios from '@/services/axios'
import {
  getPublishStatusName,
  getPublishStatusTagColor,
  PUBLISH_STATUS_ARR,
  CATALOG_PUBLISH_STATUS,
} from '@/utils/catalog'

export default defineComponent({
  components: { Edit, Delete, SlotError, ArrowDown, DocumentCopy },
  props: {
    documents: {
      type: Array as PropType<Catalog[]>,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const router = useRouter()
    // const resource = `http://localhost:3003/api/catalogs`
    const resource = `/catalogs`
    const { success, error, warning } = useNotification()
    // const itemDel = props.documents.length > 0 ? props.documents[0]._id : ''
    const alert = reactive({
      title: '',
      type: '',
      status: false,
    })
    const edit = (item: any) => {
      try {
        if (item?.dropship) {
          router.push({
            name: 'a.catalog-detail.drop',
            params: { id: item._id },
          })
        } else {
          router.push({ name: 'a.catalog-detail', params: { id: item._id } })
        }
      } catch (error) {
        console.log(error)
      }
    }

    const clone = async (id: string) => {
      if (!id) {
        error('Not found catalog id')
        return
      }
      ElMessageBox.confirm('Are you sure you want to clone this catalog?').then(
        async () => {
          const resourceClone = `${resource}/${id}/clone`
          const res = await axios.post(resourceClone)
          if (res?.status === 200 || res.status === 201) {
            success('Clone success')
          } else {
            error('Error when clone!')
          }
        }
      )
    }

    const state = reactive<{ multipleSelection: [] }>({ multipleSelection: [] })

    const handleSelectionChange = (val: any) => {
      state.multipleSelection = val
    }

    const resourceCustom = 'catalogs/publish/all'

    const onPublishSelected = async () => {
      try {
        const selecteds = state.multipleSelection.map((item: any) => item)
        const filterdedCatalogs = selecteds.filter(
          item => item.publishStatus !== 1
        )
        if (filterdedCatalogs?.length > 0) {
          const idsSelected = filterdedCatalogs.map((item: any) => item._id)
          const paramPublish = {
            ids: idsSelected,
          }
          const res = await axios.post(resourceCustom, paramPublish)
          if (res?.status === 200 || res?.status === 201) {
            success(res?.data?.message || `Update success!`)
          } else {
            error(res?.data?.message)
          }
        } else {
          warning('No catalogs to update. Catalog must be draft')
          return
        }
        emit('onAfterUpdate')
      } catch (e) {
        error('Error')
      }
    }

    const delCatalog = async (id: string) => {
      // if (id) {
      //   error('Can not delete catalog. Please contact admin.')
      //   return
      // }
      ElMessageBox.confirm('Are you sure you want to delete catalog?')
        .then(async () => {
          const { deleteOne } = useServiceCatalog(resource, {})
          const { status, data } = await deleteOne(id)
          console.log(status)
          if (status === 200) {
            success('Deleted')
            emit('onAfterUpdate')
          } else {
            error(data.message)
          }
        })
        .catch(e => {
          console.log(e)
        })
    }
    return {
      delCatalog,
      edit,
      useServiceCatalog,
      resource,
      alert,
      imageUrl,
      handleSelectionChange,
      state,
      onPublishSelected,
      publishCatalogs,
      getPublishStatusName,
      getPublishStatusTagColor,
      PUBLISH_STATUS_ARR,
      CATALOG_PUBLISH_STATUS,
      clone,
    }
  },
})
