
import { defineComponent, reactive, ref } from 'vue'
import useShopRepositories from '@/repositories/useShopRepositories'
import { SaveCatalogFromUrlDto } from '@/utils/catalog'
import { useNotification } from '@/composables'
import useCatalogRepositories from '@/repositories/useCatalogRepositories'
import { stringRules, required } from '@/utils/formRules'

export default defineComponent({
  setup(props, { emit }) {
    const { success, error, warning } = useNotification()

    const dialogVisible = ref(false)
    const isCreating = ref(false)
    const stores = ref()
    const { getAllStores } = useShopRepositories()
    const getAllStoresSellers = async () => {
      stores.value = await getAllStores()
    }
    getAllStoresSellers()

    const state = reactive({
      form: {
        productStoreUrl: '',
        productCode: '',
        store: null,
        appApiKey: '',
        appPassword: '',
        appShopUrl: '',
        type: 'drop',
      },
    })

    const onSelectStore = (e: any) => {
      const storeId: string = e
      const findStore = stores.value?.find(
        (s: { id: string }) => s.id === storeId
      )
      if (findStore) {
        state.form.appApiKey = findStore.appApiKey
        state.form.appPassword = findStore.appPassword
        state.form.appShopUrl = findStore.appShopUrl
      }
    }

    const form = ref()
    const formRules = ref({
      productStoreUrl: required('URL'),
      appShopUrl: required('App Shop Url'),
      appApiKey: required('App Api Key'),
      appPassword: required('App Password'),
    })

    const { createCatalogFromUrl } = useCatalogRepositories()
    const onCreateCatalogFromUrl = async () => {
      if (
        !state.form.productStoreUrl ||
        !state.form.appPassword ||
        !state.form.appShopUrl ||
        !state.form.appApiKey
      ) {
        warning('Input all required field!!')
        return
      }
      const handle = state.form.productStoreUrl.split('/').pop()
      if (!handle) {
        warning('Handle not found!')
        return
      }
      isCreating.value = true

      const dto: SaveCatalogFromUrlDto = {
        ...state.form,
      }
      const res: any = await createCatalogFromUrl(dto)
      if (res?.status === 200 || res?.status === 201) {
        success('Created catalog!')
        close()
        emit('onAfterCreateCatalog')
      } else {
        error(res?.message)
      }
      isCreating.value = false
    }

    const toggle = () => {
      dialogVisible.value = true
    }

    const close = () => {
      dialogVisible.value = false
    }
    return {
      dialogVisible,
      toggle,
      close,
      state,
      stores,
      getAllStoresSellers,
      onSelectStore,
      onCreateCatalogFromUrl,
      isCreating,
      formRules,
      form,
    }
  },
})
