
import { useServiceCatalog } from '@/services/useServiceCatalog'
import Pagination from '@/components/common/Pagination.vue'
import { computed, reactive, watch, ref, defineComponent } from 'vue'
import sectionLayoutContent from '@/components/common/section-layout-content.vue'
import { useFetchData } from '@/composables'
import List from './components/List.vue'
import FilterTable from '@/components/common/FilterTable.vue'
import { useRouter, useRoute } from 'vue-router'
import {
  getPublishStatusName,
  getPublishStatusTagColor,
  PUBLISH_STATUS_ARR,
  CATALOG_PUBLISH_STATUS,
} from '@/utils/catalog'
import CatalogFromUrlDialog from './components/CatalogFromUrlDialog.vue'

export default defineComponent({
  components: {
    sectionLayoutContent,
    List,
    FilterTable,
    Pagination,
    CatalogFromUrlDialog,
  },
  setup() {
    const breadcrumbs = ['Catalog', '']
    const title = 'Title'
    const resource = `/catalogs`
    const route = useRoute()
    const params = reactive({ page: 1, limit: 10, search: '' })
    const titleCreate = 'Create Catalog'
    const titleExport = 'Export Catalog'
    const titleImport = 'Create Catalog Dropship'
    const titleOtherButton1 = 'Create By URL'

    const { documents, fetchData, isLoading, total } = useFetchData(
      resource,
      params
    )
    fetchData()
    const router = useRouter()

    watch(params, fetchData)
    // watch(route, fetchData)
    watch(
      route,
      () => {
        Object.assign(params, { categories: route.query.categories })
        fetchData
      },
      { deep: true }
    )

    const create = () => {
      router.push({ name: 'a.catalog-create' })
      console.log(`create`)
    }
    const content = computed(() => {
      return {
        breadcrumbs,
        title,
        titleCreate,
        titleExport,
        titleImport,
        titleOtherButton1,
      }
    })
    const exportCatalog = () => {
      console.log(`export`)
    }
    const createDropship = () => {
      //a.catalog-create.dropship
      router.push({ name: 'a.catalog-create.dropship' })
    }
    const onFilterCallback = (data: any) => {
      params.search = data?.search
    }

    const catalogFromUrlDialog =
      ref<InstanceType<typeof CatalogFromUrlDialog>>()
    const onCreateByUrl = () => {
      catalogFromUrlDialog.value?.toggle()
    }

    const onAfterUpdate = () => {
      fetchData()
    }
    return {
      catalogFromUrlDialog,
      onCreateByUrl,
      content,
      documents,
      isLoading,
      activeKey: ref('1'),
      create,
      exportCatalog,
      useServiceCatalog,
      onFilterCallback,
      params,
      total,
      createDropship,
      onAfterUpdate,
      getPublishStatusName,
      getPublishStatusTagColor,
      PUBLISH_STATUS_ARR,
      CATALOG_PUBLISH_STATUS,
    }
  },
})
